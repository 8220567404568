import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// You'll need to add these CSS rules to your global stylesheet or component
// .leaflet-container {
//   height: 400px;
//   width: 100%;
// }

function ChangeView({ coords }) {
  const map = useMap();
  useEffect(() => {
    if (coords.length > 0) {
      map.fitBounds(coords);
    }
  }, [coords, map]);
  return null;
}

const VisorRutas = ({ desde, hasta }) => {
  const [route, setRoute] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [distancia, setDistancia] = useState('');
  const [duracion, setDuracion] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRoute = async () => {
      setIsLoading(true);
      try {
        setError(null);
        // Geocode both addresses
        const [fromCoords, toCoords] = await Promise.all([
          geocodeAddressWithFallback(desde),
          geocodeAddressWithFallback(hasta),
        ]);

        if (!fromCoords || !toCoords) {
          throw new Error(
            'No se pudieron encontrar las coordenadas para una o ambas direcciones.'
          );
        }

        setMarkers([fromCoords, toCoords]);

        // Fetch route using coordinates
        const url = `https://router.project-osrm.org/route/v1/driving/${fromCoords[1]},${fromCoords[0]};${toCoords[1]},${toCoords[0]}?overview=full&geometries=geojson`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error en la API de rutas: ${response.status}`);
        }
        const data = await response.json();
        if (data.routes && data.routes.length > 0) {
          setRoute(
            data.routes[0].geometry.coordinates.map((coord) => [
              coord[1],
              coord[0],
            ])
          );
          setDistancia(`${(data.routes[0].distance / 1000).toFixed(2)} km`);
          setDuracion(`${Math.round(data.routes[0].duration / 60)} minutos`);
        } else {
          throw new Error(
            'No se pudo encontrar una ruta entre las direcciones proporcionadas.'
          );
        }
      } catch (error) {
        console.error('Error fetching route:', error);
        setRoute([]);
        setDistancia('');
        setDuracion('');
        setError(
          error.message ||
            'No se pudo cargar la ruta. Por favor, intente de nuevo más tarde.'
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchRoute();
  }, [desde, hasta]);

  const geocodeAddressWithFallback = async (address) => {
    try {
      const coords = await geocodeAddress(address);
      if (coords) return coords;
    } catch (error) {
      console.warn(
        `Geocoding failed for address: ${address}. Trying fallback.`
      );
    }

    // If exact geocoding fails, try with just the city name
    const cityName = extractCityName(address);
    if (cityName) {
      try {
        const coords = await geocodeAddress(cityName);
        if (coords) {
          console.log(`Using approximate location for ${address}: ${cityName}`);
          return coords;
        }
      } catch (error) {
        console.error(`Fallback geocoding failed for city: ${cityName}`);
      }
    }

    throw new Error(
      `No se pudieron encontrar coordenadas para la dirección: ${address}`
    );
  };

  const geocodeAddress = async (address) => {
    const encodedAddress = encodeURIComponent(address);
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodedAddress}&limit=1`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data && data.length > 0) {
      return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
    }
    return null;
  };

  const extractCityName = (address) => {
    const parts = address.split(',');
    return parts.length > 1 ? parts[parts.length - 1].trim() : null;
  };

  const customIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  return (
    <div className="card">
      <div className="card-header">
        <h4>
          <i className="bi bi-geo-alt text-info"></i> Visor de Ruta
        </h4>
      </div>
      <div className="card-content p-1">
        <div style={{ height: '400px', width: '100%' }}>
          <MapContainer
            center={[-33.447487, -70.673676]}
            zoom={10}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.creativapp.cl">CreativApp</a> OSM'
            />
            {route.length > 0 && (
              <>
                <Polyline positions={route} color="blue" />
                <ChangeView coords={route} />
              </>
            )}
            {markers.map((position, index) => (
              <Marker key={index} position={position} icon={customIcon} />
            ))}
          </MapContainer>
        </div>
      </div>
      <div className="card-footer">
        {isLoading && <p className="text-info mb-0">Cargando ruta...</p>}
        {error && <p className="text-danger mb-0">Error: {error}</p>}
        {!isLoading && !error && (
          <>
            <p className="text-info mb-0">Distancia: {distancia}</p>
            <p className="text-info">Tiempo estimado de viaje: {duracion}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default VisorRutas;
